// SelectWithSearchBox

.badge-row {
  margin-right: 5px;
  .emankit__badge {
    margin: 0px !important;
  }
}

.user-allocation-state-option .badge-row {
  margin-left: 5px;
}
